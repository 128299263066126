import axios from "axios";
import { notifyExitoso, notifyErroneo, timeOut } from "./../Utils/alerts/Alerts.js";
import { t } from "i18next";
import { backendURL } from "../config.js";

export const handleValidateLogin = async (email, password) => {
  const formData = {
    email: email,
    password: password,
  };
  try {
    const response = await axios.post(`${backendURL}/account/login`, formData);
    if (response.status === 200) {
      localStorage.setItem("tokenlogin", response.data.tokenLogin);
      notifyExitoso(t("sesionIniciada"));
      timeOut("/", 1500);
    } else if (response.status === 205) {
      notifyErroneo(t("mailIncorrecto"));
    } else if (response.status === 203) {
      notifyErroneo(t("ElusuarioNoexiste"));
    } else {
      notifyErroneo(t("Error en la Petición"));
    }
  } catch (error) {
    console.error(error);
  }
};

export const validateKeyCluster = async (key) => {
  try {
    const response = await axios.get(`${backendURL}/cluster`, { key: key });
    if (response.status === 200) {
      return;
    } else {
      window.location.href = "/dashboard";
    }
  } catch (error) {
    console.error(error);
  }
};
